<template>
<v-container fluid>
  <v-card>
    <v-card-title>
      <span class="headline">Data SKP Yang Sudah Ditutup</span>
    </v-card-title>
    <br>
    <v-card-text>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="green white--text" @click="exportSKP"><v-icon small color="white">mdi-file-excel-box</v-icon> &nbsp;Export</v-btn>
        </div>
      </v-toolbar>
      <v-data-table
          :headers="tahun < 2022 ? headers : currentHeaders"
          :items.sync="dataSKP"
          :page.sync="page"
          :items-per-page.sync="rowPerPage"
          :footer-props="{'items-per-page-options':[5,10,15]}"
          :server-items-length="totalKeseluruhan"
          :loading="loadingLihatSKP"
        >
        <!-- Nomor -->
        <template v-slot:[`item.no`]="{index}">
          {{index+1}}
        </template>

        <!-- Indikator -->
        <template v-slot:[`item.indikator`]="{item}">
          <template v-if="item.indikator_kuantitas != null">Kuantitas: {{ item.indikator_kuantitas }}</template>
          <template v-if="item.indikator_kualitas != null">Kualitas: {{ item.indikator_kuantitas }}</template>
          <template v-if="item.indikator_waktu != null">Waktu: {{ item.indikator_waktu }}</template>
        </template>

        <!-- Target Kuantitas -->
        <template v-slot:[`item.target`]="{item}">
          <div v-if="tahun < 2022">
            Target: 
          <template v-if="item.revisi_target == null">{{ item.target }} {{ item.satuan_target }}</template>
          <template v-else><strike>{{ item.target }} {{ item.satuan_target }}</strike><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.revisi_target}} {{item.satuan_target}}</template>
          <br><br>
          Realisasi: 
          <template v-if="item.realisasi != null">{{ item.realisasi }} {{ item.satuan_realisasi }}</template>
          <template v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Belum ada realisasi</template>
          <br><br>
          Kualitas: {{ item.kualitas }}%<br><br>
          </div>
          <div v-else>
            <template v-if="item.indikator_kuantitas != null">
              <br>
              <template v-if="item.target_atas != null">Target: {{ item.target_bawah }} - {{ item.target_atas }} {{ item.satuan_target }}</template>
              <template v-else>Target: {{ item.target_bawah }} {{ item.satuan_target }}</template>
              <br><br>
              Realisasi:
              <template>{{ item.realisasi_kuantitas }} {{ item.satuan_realisasi }}</template>
              <br><br>
            </template>
          </div>
        </template>

        <!-- Target Waktu -->
        <template v-slot:[`item.waktu`]="{item}">
          <div v-if="tahun < 2022">
            Waktu:
            <template v-if="item.revisi_waktu == null">{{ item.waktu }} {{ item.satuan_waktu }}</template>
            <template v-else><strike>{{ item.waktu }} {{ item.satuan_waktu }}</strike><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.revisi_waktu}} {{item.satuan_waktu}}</template>
          </div>
          <div v-else>
            <template v-if="item.indikator_waktu != null">
              <br>
              <template v-if="item.waktu_batas_atas != null">Target: {{ item.waktu_batas_bawah }} - {{ item.waktu_batas_atas }} {{ item.satuan_target }}</template>
              <template v-else>Target: {{ item.waktu_batas_bawah }} {{ item.satuan_target }}</template>
              <br><br>
              Realisasi:
              <template>{{ item.realisasi_kuantitas }} {{ item.satuan_realisasi }}</template>
              <br>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>

        <!-- Target Kualitas -->
        <template v-slot:[`item.kualitas`]="{item}">
          <template v-if="item.indikator_kualitas != null">
            <br>
            <template v-if="item.kualitas_batas_atas != null">Target: {{ item.kualitas_batas_bawah }} - {{ item.kualitas_batas_atas }} {{ item.satuan_target }}</template>
            <template v-else>Target: {{ item.kualitas_batas_bawah }} {{ item.satuan_target }}</template>
            <br><br>
            Realisasi:
            <template>{{ item.realisasi_kualitas }} {{ item.satuan_realisasi }}</template>
            <br>
          </template>
          <template v-else>
            -
          </template>
        </template>

        <!-- Target Bobot -->
        <template v-slot:[`item.bobot`]="{item}">
          <template v-if="item.bobot != null">
            <strong v-if="item.bobot == 1" class="red--text">Di bawah Ekspektasi</strong>
            <strong v-if="item.bobot == 2" class="primary--text">Sesuai Ekspektasi</strong>
            <strong v-if="item.bobot == 3" class="success--text">Di atas Ekspektasi</strong>
          </template>
          <template v-else>
            -
          </template>
        </template>

        <template v-slot:[`body.append`]>
          <tr v-if="tahun < 2022">
            <td colspan="7">
              <v-row justify="end"><b>Total Nilai Capaian SKP</b></v-row>
            </td>
            <td>
              <b>{{ nilaiTotalCapaian }}</b>
            </td>
          </tr>
        </template>
        <template v-slot:[`item.aksi`]="{item}">
          <br>
          <v-btn small color="yellow darken-1 black--text" @click="editButirSKP(item)"><v-icon small>mdi-pencil</v-icon> &nbsp;Ubah</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-spacer></v-spacer>
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog v-model="dialogEditTargetRealisasi" max-width="600px">
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <h1 class="display-2 my-2">Edit Target Dan Realiasi SKP</h1>
          <template v-if="tahun < 2022">
          </template>
          <template v-else>
            <v-row v-if="formEdit.indikator_kuantitas != null">
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.target_bawah" outlined label="Target Batas Bawah"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.target_atas" outlined label="Target Batas Atas"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select dense v-model="formEdit.satuan_target" outlined :items="satuanSKP" label="Satuan"></v-select>
              </v-col>
            </v-row>
            <v-row v-if="formEdit.indikator_kualitas != null">
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.kualitas_batas_bawah" outlined label="Target Kualitas Batas Bawah"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.kualitas_batas_atas" outlined label="Target Kualitas Batas Atas"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="formEdit.indikator_waktu != null">
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.waktu_batas_bawah" outlined label="Target Waktu Batas Bawah"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.waktu_batas_atas" outlined label="Target Waktu Batas Atas"></v-text-field>
              </v-col>
            </v-row>
            <hr>
            <br>
            <v-row v-if="formEdit.realisasi_kuantitas != null">
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.realisasi_kuantitas" outlined label="Realisasi Kuantitas"></v-text-field>
              </v-col>
              <v-col cols="4">
                {{formEdit.satuan_realisasi}}
              </v-col>
            </v-row>
            <v-row v-if="formEdit.realisasi_kualitas != null">
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.realisasi_kualitas" outlined label="Realisasi Kualitas"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="formEdit.realisasi_waktu != null">
              <v-col cols="4">
                <v-text-field dense v-model="formEdit.realisasi_waktu" outlined label="Realisasi Waktu"></v-text-field>
              </v-col>
            </v-row>
            <hr>
            <v-radio-group v-model="formEdit.bobot" column>
              <v-radio color="red" value=1>
                <template v-slot:label>
                  <strong class="red--text">Di bawah Ekspektasi</strong>
                </template>
              </v-radio>
              <v-radio color="primary" value=2>
                <template v-slot:label>
                  <strong class="primary--text">Sesuai Ekspektasi</strong>
                </template>
              </v-radio>
              <v-radio color="success" value=3>
                <template v-slot:label>
                  <strong class="success--text">Di atas Ekspektasi</strong>
                </template>
              </v-radio>
            </v-radio-group>
          </template>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="yellow darken-1 black--text" @click="saveEditTargetRealisasi(formEdit)" :loading="loadingBtn.dialogEditTargetRealisasi" dark>Simpan</v-btn>
        <v-btn @click="dialogEditTargetRealisasi=false">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import _g from '@/global'
import {obj2Arr, defaultRules} from '@/utils/lib'
import SKPService from '@/services/SKPService'
import axios from 'axios'
// import LihatSKP from './LihatSKP.vue'

export default {
  props:{
    dataSKP:Array,
    loadingLihatSKP:Boolean,
    totalKeseluruhan:Number,
    nilaiTotalCapaian:Number,
    selected:Object
  },
  components:{
  },
  data(){
    return{
      currentHeaders:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Sasaran Kinerja Pegawai', value:'rencana_kinerja', sortable:false, class:'header-index-profile', width:"12%"},
        {text:'Indikator Kuantitas/Kualitas/Waktu', value:'indikator', sortable:false, class:'header-index-profile', width:"12%"},
        {text:'Angka Kredit', value:'angka_kredit', sortable:false, class:'header-index-profile', width:"18%"},
        {text:'Kuantitas Batas Bawah/Atas', value:'target', sortable:false, class:'header-index-profile'},
        {text:'Kualitas Batas Bawah/Atas', value:'kualitas', sortable:false, class:'header-index-profile'},
        {text:'Waktu Batas Bawah/Atas', value:'waktu', sortable:false, class:'header-index-profile'},
        {text:'Bobot', value:'bobot', sortable:false, class:'header-index-profile'},
        // {text:'Penghitungan', value:'penghitungan', sortable:false, class:'header-index-profile'},
        // {text:'Nilai Capaian', value:'nilai_capaian', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Sasaran Kinerja Pegawai', value:'rencana_kinerja', sortable:false, class:'header-index-profile', width:"12%"},
        {text:'Angka Kredit', value:'angka_kredit', sortable:false, class:'header-index-profile', width:"18%"},
        {text:'Target Output', value:'target', sortable:false, class:'header-index-profile'},
        {text:'Waktu dan Biaya', value:'waktu', sortable:false, class:'header-index-profile'},
        {text:'Kualitas Mutu', value:'kualitas_mutu', sortable:false, class:'header-index-profile'},
        {text:'Penghitungan', value:'penghitungan', sortable:false, class:'header-index-profile'},
        {text:'Nilai Capaian', value:'nilai_capaian', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[],
      rowPerPage:0,
      page:1,
      test: '',
      loading:false,
      nilai_total_capaian_ : 0,
      dialogEditTargetRealisasi:false,
      formEdit:{},
      tahun:null,
      rules:{
        ...defaultRules
      },
      loadingBtn:{
        dialogEditTargetRealisasi:false,
      },
      satuanSKP:[],
    }
  },

  computed:{
  },

  watch:{
    
  },

  created(){
    this.tahun = this.$store.getters["user/data"].tahun
    // mengambil database
    SKPService.satuanSKP().then(response=>{
      this.satuanSKP = response.data.data.sort((a,b)=>{
        return a.toLowerCase().localeCompare(b.toLowerCase())
      })
    })
  },

  mounted(){
    this.tahun = this.$store.getters["user/data"].tahun
    // this.nilai_total_capaian_ = this.nilaiTotalCapaian
  },

  methods:{
    closeEvent(){
      this.$emit('onClose',false)
    },
    editButirSKP(item){
      this.dialogEditTargetRealisasi = true
      this.formEdit = item
      this.formEdit.bobot = item.bobot.toString()
    },
    saveEditTargetRealisasi(v){
      var url = "v1/update-skp-tutup-admin"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {...v}).then(response => {
        let res = response.data
        this.dialogEditTargetRealisasi = false
        if(res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          // this.updateTable(v)
          this.$emit('onTableChange',this.selected)
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).catch(err => {
        debugger
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },
    updateTable(v){

    },
    exportSKP(){
      var current = this.$store.getters["user/current"]
      var year = current.year
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      var id_sasaran_kinerja = this.selected.id_sasaran_kinerja;
      var nip = this.selected.nip;
      var jabatan_nama = this.selected.jabatan_nama;
      var unit_kerja = this.selected.unit_kerja;
      var data = {
        "id_sasaran_kinerja" : id_sasaran_kinerja,
        "nip" : nip,
        "jabatan_nama" : jabatan_nama,
        "unit_kerja" : unit_kerja
      }
      var urls = base+year+"/api/export-tutup-skp-admin?data="+JSON.stringify(data);
      window.open(urls,'_blank');
    }
  },
}
</script>

<style lang="css" scoped>
.label{
  font-size:12px
}
.row + .row {
    margin-top: 0px;
}
</style>
